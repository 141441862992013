import { useState, useEffect } from "react"

//firebase imports
import { projectFirestore } from "../firebase/config"
import { collection, onSnapshot } from 'firebase/firestore'

export const useCollection = ( firestoreCollection ) => {
    const [documents, setDocuments] = useState(null)

    useEffect( () => {
        let ref = collection(projectFirestore, firestoreCollection)

        //this allows real time data in the application
        //first argument is the collection and second is a function that fires everytime the collection changes
        const unsubscribe = onSnapshot(ref, (snapshot) => {
            let results = []
            snapshot.docs.forEach( doc => {
                results.push({ id: doc.id, ...doc.data() })
            })
            setDocuments(results)
        })
        // in case the components unmount we unsubscribe it here
        return () => unsubscribe()
    }, [firestoreCollection])

    return { documents }
}