import { Link } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useLogout } from '../../hooks/useLogout'

//components
import Logo from '../../assets/temple.svg' 

//styles
import './Navbar.css'

export default function Navbar() {

  const { logout, isPending } = useLogout()
  const { user } = useAuthContext()

  return (
    <nav className='main-nav'>
      <img src={Logo} alt='website logo'/>
      <ul className='main-nav__items'>
        <li className='main-nav__item'>
          <Link to='/'>Home</Link>
        </li>
        <li className='main-nav__item'>
          <Link to='/listing'>Listing</Link>
        </li>
        <li className='main-nav__item'>
          <Link to='/review'>Add Review</Link>
        </li>
      </ul>
      { !user && ( <Link to='/login'><button className='btn main-nav__btn' type="button">LOGIN</button></Link> ) }
      { user && !isPending && <button className='btn main-nav__btn' type="button" onClick={logout}>LOGOUT</button> }
      { user && isPending && <button className='btn main-nav__btn' disabled>LOGGING OUT</button> }
    </nav>    
  )
}