import { useState } from 'react'
import { useLoginEmailLink } from '../../hooks/useLoginEmailLink'
import { useLoginGmail } from '../../hooks/useLoginGmail'

//styles
import './Login.css'

export default function Login() {
  const [email, setEmail] = useState('')
  const { loginEmailLink, isPending, error }  = useLoginEmailLink()
  const { loginGmail } = useLoginGmail()


  const handleSubmit = (e) => {
    e.preventDefault()
    loginEmailLink(email)
  }

  const handleClick = () => {
    loginGmail()
  }

  return (
    <div>
      <h2>Passwordless SignIn</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <span>email:</span>
          <input 
            required
            type='email'
            onChange={ (e) => setEmail(e.target.value)}
            value={email}
          />
        </label>
        { !isPending && <button className="btn">LOG IN</button> }
        { isPending && <button className="btn" disabled>SENDING LINK</button> }
        { error && <div className='error'>{ error }</div>}
      </form>
      <button onClick={handleClick}>GMAIL</button>
    </div>

  )
}


