import { useState, useEffect } from "react";

//imports firebase
import { sendSignInLinkToEmail } from "firebase/auth";
import { projectAuth } from '../firebase/config'


export const useLoginEmailLink = () => {
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [isCancelled, setIsCancelled] = useState(false)
    const actionCodeSettings = {
        url: 'https://mylandlordis.com/auth/link',
        handleCodeInApp: true
      };
 
    const loginEmailLink = (email) => {
        setError(null)
        setIsPending(true)
        
        sendSignInLinkToEmail(projectAuth, email, actionCodeSettings)
          .then(() => {
            window.localStorage.setItem('emailForSignIn', email);

            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }

          })
          .catch((err) => {
            if (!isCancelled) {
                setError(err.message)
                setIsPending(false)
            }
          });

    }

    useEffect(() => {
        setIsCancelled(false);
        return () => setIsCancelled(true)
      }, [])

    return { loginEmailLink, isPending, error } 

}



  
