//hooks
import { useCollection } from '../../hooks/useCollection'

//pages and components
import LandlordList from '../../components/landlord-list/LandlordList'

//styles
import './Listing.css'

export default function Listing() {
  const { documents: listings } = useCollection('listings')

  return (
    <div>
      { listings && <LandlordList listings={listings} />}
    </div>
  )
}
