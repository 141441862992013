import { useState } from 'react'

//imports firebase
import { projectFirestore } from '../../firebase/config'
import { collection, addDoc } from 'firebase/firestore'

//styles
import './Review.css'

export default function Review() {
    const [ review, setReview ] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()

        const ref = collection(projectFirestore, 'listings')
        await addDoc(ref, {
            address: review
        })
        
        setReview('')
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    <span>Add new review</span>
                    <input 
                        required
                        type='text'
                        onChange={ (e) => setReview(e.target.value) }
                        value={review}
                    />
                </label>
                <button>Add review</button>
            </form>
        </div>
    )
    }
