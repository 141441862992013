import './Home.css'
import Image from '../../assets/casino_isometric.png'

export default function Home() {
  return (
    <div className="div-list">
      <div>
        <h1>Rate your landlord or find reviews</h1>
        <p>We'll help you find a place to call home through reviews frompeople about the place and landlord</p>
        <button className='btn'>CREATE REVIEW</button>
      </div>
      <div>
        <img src={Image} alt='applogo'/>
      </div>
    </div>
  )
}
