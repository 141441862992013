import { useState, useEffect } from 'react'

import { useAuthContext } from '../hooks/useAuthContext'

//firebase imports
import { projectAuth } from '../firebase/config'
import { signOut } from 'firebase/auth'

export const useLogout = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    const logout = () => {
        setError(null)
        setIsPending(true)

        signOut(projectAuth)
            .then( () => {
                dispatch({ type: 'LOGOUT' })
                if(!isCancelled) {
                    setError(null)
                    setIsPending(false)
                }    
            })
            .catch( (err) => {
                if (!isCancelled) {
                    console.log(err.message)
                    setError(err.message)
                    setIsPending(false)
                }
            })

        setIsPending(false)
    }

    //This fixes error when the user quickly change the page and unmount the component
    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { logout, error, isPending }

}