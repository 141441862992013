import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { projectAuth } from '../../firebase/config'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function RedirectEmailLogin() {
  const { dispatch } = useAuthContext()
  const navigate = useNavigate();
  const [error, setError] = useState()

    // Confirm the link is a sign-in with email link.
  if (isSignInWithEmailLink(projectAuth, window.location.href)) {
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }

    signInWithEmailLink(projectAuth, email, window.location.href)
      .then((res) => {
        window.localStorage.removeItem('emailForSignIn');
        dispatch({ type: 'LOGIN', payload: res.user })
        navigate("/");
      })
      .catch((err) => {
        setError(err.message)
      });

  }

  return (
    <div>
        Loading authentication, you will be redirected shortly
        { error && <div className='error'>{ error }</div> }
    </div>
  )
}
