import { BrowserRouter, Routes, Route, Navigate }  from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

//components
import Home from './pages/home/Home'
import Listing from './pages/listing/Listing'
import Login from './pages/login/Login'
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Review from './pages/review/Review';


//styles
import './App.css';
import RedirectEmailLogin from './pages/auth/RedirectEmailLogin'

function App() {

  const { user, authIsReady } = useAuthContext()

  return (
    <div className="App">
      { authIsReady && (
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route 
              path='/' 
              element={<Home />} />
            <Route 
              path='/listing' 
              element={<Listing />} />
            <Route 
              path='/login' 
              element={ !user ? <Login /> : <Navigate to='/listing' />} />
            <Route 
              path='/review' 
              element={ user ? <Review /> : <Navigate to='/login' /> } />
            <Route 
              path='/auth/link' 
              element={ <RedirectEmailLogin />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
