import { projectFirestore } from '../../firebase/config'
import { doc, deleteDoc } from 'firebase/firestore'

//styles
import './LandlordList.css'

export default function LandlordList({ listings }) {

    const handleClick = async (id) => {
        const ref = doc( projectFirestore, 'listings', id)
        await deleteDoc(ref)

    }

    return (
        <div>
            { listings.map( listing => (
                <li key={listing.id} onClick={ () => handleClick(listing.id)}>{listing.address}</li>
            ))}
        </div>
    )
}
