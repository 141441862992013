import { useState, useEffect } from 'react'

import { useAuthContext } from '../hooks/useAuthContext'

//firebase imports
import { projectAuth } from '../firebase/config'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

export const useLoginGmail = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()
    //const [value,setValue] = useState('')

    const loginGmail = () => {
        setError(null)
        setIsPending(true)  

        const provider = new GoogleAuthProvider()
        signInWithPopup(projectAuth, provider)
            .then( (res) => {
                dispatch({ type: 'LOGIN', payload: res.user })
                if (!isCancelled) {
                    setIsPending(false)
                    setError(null)
                }

                const credential = GoogleAuthProvider.credentialFromResult(res)
                console.log(res.user.email)
                
                // This gives you a Google Access Token. You can use it to access the Google API.
                //const credential = GoogleAuthProvider.credentialFromResult(res);
                //const token = credential.accessToken;
                // The signed-in user info.
                //const user = result.user;
                //setValue(res.user.email)
                //localStorage.setItem("email",res.user.email)


            }).catch( (err) => {
                console.log(err)
                if (!isCancelled) {
                    setError(err.message)
                    setIsPending(false)
                }
                // Handle Errors here.
                //const errorCode = error.code;
                //setError(err.message)
                // The email of the user's account used.
                //const email = err.customData.email;
                // The AuthCredential type that was used.
                //const credential = GoogleAuthProvider.credentialFromError(error);

            })
    }

    useEffect(() => {
        setIsCancelled(false);
        return () => setIsCancelled(true)
      }, [])

    return { loginGmail, isPending, error }
}